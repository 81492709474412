import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NotificationService } from "@services/notification.service";
import { StripeService } from "@services/stripe.service";

type SubComponents = "methods" | "add" | "input";

@Component({
  selector: "app-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.scss"],
})
export class PaymentMethodComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("cardElement") cardElement: ElementRef;
  @Input() disable: SubComponents[] = [];
  @Input() disableDarkMode: boolean = false;

  card: stripe.elements.Element;
  cardErrors;
  paymentMethods;
  paymentToggles: boolean[];
  loading = true;

  constructor(
    private stripeService: StripeService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.stripeService.initializeCardElement({
      disableDarkMode: this.disableDarkMode,
    });
    this.stripeService.getPaymentMethods().subscribe(
      ({ cards }) => {
        console.log(cards);
        this.paymentMethods = cards;
        this.paymentToggles = new Array(cards.length).fill(false);
      },
      (err) => console.log(err),
      () => {
        this.loading = false;
      }
    );
  }

  ngAfterViewInit(): void {
    this.card = this.stripeService.cardElement;
    this.card.mount(this.cardElement.nativeElement);
    this.card.addEventListener("change", ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  ngOnDestroy() {
    // this.card.destroy();
  }

  async addPaymentMethod() {
    this.loading = true;
    await this.stripeService.confirmCardSetup();
    this.stripeService.getPaymentMethods().subscribe((cards) => {
      if (this.paymentMethods) {
        this.paymentMethods = cards;
        this.paymentToggles = new Array(cards.length).fill(false);
        this.card.clear();
      }
      this.loading = false;
    });
  }

  toggleOpen(e: Event, index: number) {
    e.stopPropagation();
    e.preventDefault();
    if (!this.paymentToggles.length) return;
    this.paymentToggles[index] = !this.paymentToggles[index];
  }

  async removePaymentMethod(e: Event, index: number) {
    e.stopPropagation();
    e.preventDefault();
    this.paymentMethods.loading = true;
    const card = this.paymentMethods[index];
    this.stripeService.removePaymentMethod(card.id).subscribe(
      () => {
        this.paymentMethods.splice(index, 1);
        this.notificationService.handleSuccess(
          "Successfully removed payment method."
        );
      },
      (err) => {
        this.notificationService.handleError(
          "Unable to remove payment method."
        );
      },
      () => {
        this.paymentMethods.loading = false;
      }
    );
  }
}
