import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-archive",
  templateUrl: "./archive.component.html",
  styleUrls: ["./archive.component.scss"],
})
export class ArchiveComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
