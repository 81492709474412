import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-trending-up",
  templateUrl: "./trending-up.component.html",
  styleUrls: ["./trending-up.component.scss"],
})
export class TrendingUpComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
