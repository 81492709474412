<div
  class="
    max-w-lg
    w-full
    my-4
    p-4
    border-2 border-light-400
    rounded-lg
    cursor-pointer
    hover:shadow
    flex flex-col
    items-center
    animated
    animate-pulse
    duration-100
  "
>
  <p class="h-6 my-1 w-32 rounded bg-gray-900 animate-pulse"></p>
  <p class="h-5 my-1 w-36 rounded bg-gray-900 animate-pulse"></p>
  <p class="h-3 my-1 w-full rounded bg-gray-900 animate-pulse"></p>
  <p class="h-3 my-1 w-full rounded bg-gray-900 animate-pulse"></p>
</div>
