import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@services/auth.service";
import { AdminRoles } from "@shared/models";
@Component({
  selector: "timeline-step-status",
  templateUrl: "./timeline-step-status.component.html",
  styleUrls: ["./timeline-step-status.component.scss"],
})
export class TimelineStepStatusComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("statusRef") statusRef: ElementRef<HTMLDivElement>;
  @Input("status") status:
    | "Not Started"
    | "Archived"
    | "Completed"
    | "In Progress";
  @Input() mocked: boolean = false;
  @Output() statusChange = new EventEmitter<string>();

  open: boolean = false;
  admin: boolean = false;
  form: FormGroup;
  bottom = "-56px";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.admin = AdminRoles.includes(this.authService.getRole()) || this.mocked;
    this.form = this.formBuilder.group({
      status: ["", Validators.required],
    });
  }

  ngAfterViewInit(): void {
    this.document.addEventListener("click", (e) =>
      this.offClickHandler(e, this.statusRef.nativeElement)
    );
    this.form.valueChanges.subscribe(({ status }) => {
      this.status = status;
      this.statusChange.emit(status);
      this.open = false;
    });
  }

  ngOnDestroy() {
    this.document.removeEventListener("click", (e) =>
      this.offClickHandler(e, this.statusRef.nativeElement)
    );
  }

  offClickHandler(e: MouseEvent, ele: HTMLDivElement) {
    if (!e.composedPath().includes(ele)) {
      this.open = false;
    }
  }

  handleOpen(event: Event) {
    event.stopPropagation();
    if (!this.admin) return;
    switch (this.status) {
      case "Archived":
        this.bottom = "-2px";
        break;
      case "Completed":
        this.bottom = "-34px";
        break;
      case "In Progress":
        this.bottom = "-66px";
        break;
      case "Not Started":
        this.bottom = "-98px";
        break;
      default:
        break;
    }
    this.open = true;
  }
}
