import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private http: HttpClient) {}

  private handleErrors(error: any) {
    console.error(error);
    return throwError(error);
  }

  get(
    path: string,
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        } = new HttpParams(),
    options?
  ): Observable<any> {
    return this.http
      .get(`${environment.baseUrl}${path}`, { params, ...options })
      .pipe(catchError(this.handleErrors));
  }

  put(path: string, body: any = {}, options?): Observable<any> {
    return this.http
      .put(`${environment.baseUrl}${path}`, body, { ...options })
      .pipe(catchError(this.handleErrors));
  }

  post(path: string, body: any = {}, options?): Observable<any> {
    return this.http
      .post(`${environment.baseUrl}${path}`, body, { ...options })
      .pipe(catchError(this.handleErrors));
  }

  delete(path): Observable<any> {
    return this.http
      .delete(`${environment.baseUrl}${path}`)
      .pipe(catchError(this.handleErrors));
  }
}
