import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "icon-button",
  templateUrl: "./icon-button.component.html",
  styleUrls: ["./icon-button.component.scss"],
})
export class IconButtonComponent implements OnInit {
  @Input() set dark(dark: boolean) {
    this._dark = dark;
  }
  @Input() id: string;

  _dark = false;

  constructor() {}

  ngOnInit(): void {}
}
