import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-clock",
  templateUrl: "./clock.component.html",
  styleUrls: ["./clock.component.scss"],
})
export class ClockComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
