import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-download",
  templateUrl: "./download.component.html",
  styleUrls: ["./download.component.scss"],
})
export class DownloadComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
