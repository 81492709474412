import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-terminal",
  templateUrl: "./terminal.component.html",
  styleUrls: ["./terminal.component.scss"],
})
export class TerminalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
