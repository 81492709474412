<div
  class="w-full px-4 flex items-center justify-between border-2 rounded p-4 animate-pulse"
>
  <div class="flex flex-col space-y-2">
    <div class="bg-gray-200 dark:bg-gray-800 rounded w-28 h-4"></div>
    <div class="bg-gray-200 dark:bg-gray-800 rounded w-20 h-8"></div>
    <div class="bg-gray-200 dark:bg-gray-800 rounded w-20 h-4"></div>
  </div>

  <div class="bg-gray-200 dark:bg-gray-800 rounded w-32 h-10"></div>
</div>
