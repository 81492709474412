<button
  [id]="id"
  class="p-2 rounded-full shadow hover:shadow-md dark:shadow-gray-600 flex justify-center items-center group transition-colors duration-100 ease-in-out"
  [ngClass]="{
    'bg-gray-900 dark:bg-gray-300 active:bg-gray-800 active:dark:bg-gray-300':
      _dark,
    'bg-white active:bg-gray-100 dark:bg-black active:dark:bg-darkgray-800':
      !_dark
  }"
>
  <ng-content select="[icon]"></ng-content>
  <!-- <p select="[text]" class="group-hover:flex"></p> -->
</button>
