<div>
  <div class="flex justify-center w-full">
    <app-billing-length-toggle
      [(length)]="planDuration"
    ></app-billing-length-toggle>
  </div>

  <div>
    <div class="flex flex-col mt-4">
      <ng-container *ngIf="!loading">
        <ng-container *ngFor="let plan of plans">
          <ng-container *ngIf="plan.term == planDuration">
            <div
              [ngClass]="{
                ' border-medium-purple-500 shadow-md':
                  selectedPlan?._id == plan._id,
                ' border-transparent': selectedPlan?._id != plan._id
              }"
              class="w-full px-4 flex items-center justify-between border-2 rounded p-4"
            >
              <div
                class="flex flex-col items-start justify-items-center font-standard"
              >
                <div
                  class="font-bold text-stone-900 dark:text-gray-50 font-exo2"
                >
                  {{ plan.name }}
                </div>
                <div class="text-4xl font-bold mt-4">
                  ${{ plan.cost
                  }}<span
                    class="text-stone-500"
                    [ngClass]="{
                      'text-sm': plan.name == 'Prohura Premium',
                      'text-lg': plan.name != 'Prohura Premium'
                    }"
                    >{{ plan.term_desc }}</span
                  >
                </div>
                <ng-container *ngIf="!plan.meeting">
                  <div class="text-stone-500 mt-2">{{ plan.users }}</div>
                </ng-container>
                <ng-container *ngIf="plan.meeting">
                  <div class="text-stone-500 mt-2 text-sm">
                    {{ plan.users }}
                  </div>
                  <div class="text-stone-500 text-sm">{{ plan.meeting }}</div>
                </ng-container>
              </div>

              <div
                *ngIf="selectedPlan?._id == plan._id"
                class="text-gray-700 dark:text-gray-400 font-bold text-sm flex items-center"
              >
                <icon-check class="w-5 h-5"></icon-check>
                <div>Selected Plan</div>
              </div>

              <secondary-button
                *ngIf="selectedPlan?._id != plan._id"
                (click)="selectPlan(plan)"
                [id]="plan.lookup_key"
                ><ng-container text>Select Plan</ng-container>
              </secondary-button>
            </div>
            <div
              class="w-full border-b border-gray-300 dark:border-gray-700"
            ></div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="loading">
        <app-plan-loading-item
          class="mt-1"
          *ngFor="let item of [1, 2, 3]"
        ></app-plan-loading-item>
      </ng-container>
    </div>
  </div>

  <div class="my-4 flex justify-end" *ngIf="!showPromoCode">
    <div
      class="text-gray-700 text-sm p-2 hover:underline active:underline active:text-black cursor-pointer"
      (click)="showPromoCode = true"
    >
      Promo Code
    </div>
  </div>

  <div class="my-4 flex justify-end items-center" *ngIf="showPromoCode">
    <app-input
      class="scale-90 mt-4"
      ngDefaultControl
      [(value)]="promoCode"
      type="text"
      label="Promo Code"
    >
    </app-input>
    <secondary-button (click)="applyPromoCode()">
      <p text>Apply Code</p>
    </secondary-button>
  </div>
</div>
