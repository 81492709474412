import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
