import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-cog",
  templateUrl: "./cog.component.html",
  styleUrls: ["./cog.component.scss"],
})
export class CogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
