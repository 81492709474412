import { Component, OnInit } from "@angular/core";

@Component({
  selector: "payment-method-loading",
  templateUrl: "./payment-method-loading.component.html",
  styleUrls: ["./payment-method-loading.component.scss"],
})
export class PaymentMethodLoadingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
