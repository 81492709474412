import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { PasswordValidator } from "src/app/validators/password.validator";
import { AppErrorStateMatcher } from "../../../utils/mat-matchers/app-errorstate.matcher";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent implements AfterViewInit {
  @Input() set value(val: string) {
    this._value = val;
    if (this.isValidInputValue(this._value)) {
      this.inputFormControl.setValue(this._value);
    }
  }
  @Input() set defaultValue(value: string | null) {
    if (this._defaultValue === null && this.isValidInputValue(value)) {
      this._defaultValue = JSON.parse(JSON.stringify(value));
    }
  }
  @Input() set error(error: string) {
    console.log(error);
    this._error = error;
  }
  get defaultValue() {
    return this._defaultValue;
  }
  @Input() label: string;
  @Input() id: string;
  @Input() placeholder: string = "";
  @Input() type: string;
  @Input() required = false;
  @Input() onFocus: () => void = () => {};
  @Input() debounceValidation: (value: string) => void;
  @Output() valueChange = new EventEmitter<string>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() hasDiverged = new EventEmitter<boolean>();
  @ViewChild("iconSlot") iconSlot;

  inputFormControl: FormControl;
  matcher = new AppErrorStateMatcher();
  focus = false;
  formattedLabel: string;
  hasIcon: boolean;
  minlength;
  maxlength: number = Number.MAX_SAFE_INTEGER;
  _error: string;
  _value: string;
  _defaultValue: string | null = null;
  _hasDiverged: boolean = false;
  name_max_length = 50;
  name_min_length = 4;

  constructor(private ref: ChangeDetectorRef) {
    this.inputFormControl = new FormControl("", []);
  }

  ngOnInit() {
    this.selectValidations();
    if (this.required && this.label && this.label.length > 0) {
      this.formattedLabel = this.label + " *";
    } else {
      this.formattedLabel = this.label;
    }
    this.inputFormControl.valueChanges.subscribe((val) => {
      this.valid.emit(this.inputFormControl.valid);
      this.valueChange.emit(val);
      this._hasDiverged = val !== this._defaultValue;
      this.hasDiverged.emit(this._hasDiverged);
    });

    this.inputFormControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(this.debounceValidation);
  }

  ngAfterViewInit() {
    this.hasIcon =
      this.iconSlot.nativeElement &&
      this.iconSlot.nativeElement.children?.length > 0;
    this.ref.detectChanges();
  }

  private isValidInputValue(inputValue) {
    return (
      inputValue !== null &&
      inputValue !== undefined &&
      typeof inputValue === "string" &&
      inputValue.toString().length >= 0
    );
  }

  public reset() {
    this.inputFormControl.reset(this.defaultValue);
  }

  public clearInput() {
    this.inputFormControl.reset();
  }

  selectValidations() {
    const validations = [];
    if (this.required) {
      validations.push(Validators.required);
    }
    if (this.type == "password") {
      validations.push(Validators.minLength(6));
      validations.push(PasswordValidator());
    }
    if (this.type == "name") {
      this.maxlength = this.name_max_length;
      this.minlength = this.name_min_length;
      validations.push(Validators.minLength(this.minlength));
      validations.push(Validators.maxLength(this.maxlength));
    }
    if (this.type == "email") {
      validations.push(
        Validators.pattern(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      );
    }

    this.inputFormControl.setValidators(validations);
    this.inputFormControl.updateValueAndValidity();
  }

  handleFocus() {
    this.focus = true;
    this.onFocus();
  }

  handleBlur() {
    this.focus = false;
  }
}
