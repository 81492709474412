<div class="mt-6 mb-2" *ngIf="loading">
  <payment-method-loading></payment-method-loading>
</div>

<div *ngIf="!disable.includes('methods')">
  <div
    class="mt-6 mb-2"
    *ngFor="let paymentMethod of paymentMethods; let i = index"
  >
    <div
      (click)="toggleOpen($event, i)"
      class="px-1 py-2 rounded hover:shadow hover:text-gray-600 dark:hover:text-gray-400 text-transparent cursor-pointer overflow-hidden"
      [ngClass]="[
        paymentToggles[i] ? 'shadow text-gray-600 dark:text-gray-400' : '',
        paymentMethod.loading ? 'opacity-70' : ''
      ]"
    >
      <div class="flex items-center">
        <span class="w-48 flex items-center">
          <icon-visa class="h-auto w-6 mr-4"></icon-visa>
          <p class="text-gray-800 dark:text-gray-300">
            **** {{ paymentMethod.card.last4 }}
          </p>
        </span>
        <!-- <div
        *ngIf="paymentMethod.default"
        class="px-1 mx-2 bg-light-200 text-gray-700 text-xs rounded"
      >
        Default
      </div> -->
        <div class="text-gray-800 dark:text-gray-300">
          Expires
          {{
            paymentMethod.card.exp_month.toLocaleString("en-us", {
              minimumIntegerDigits: 2
            })
          }}
          / {{ paymentMethod.card.exp_year }}
        </div>
        <icon-chevron-down class="ml-auto h-4 w-4"></icon-chevron-down>
      </div>
      <div
        class="transform duration-100"
        [ngClass]="paymentToggles[i] ? 'h-24' : 'h-0'"
      >
        <div class="pt-2 flex items-end">
          <p class="text-gray-800 dark:text-gray-300 w-48">
            <strong>
              {{ paymentMethod.billing_details.name || "Cardholder Name" }}
              <br />
            </strong>
            {{
              paymentMethod.billing_details.address.line1 || "Street Address"
            }}
            <br />
            {{ paymentMethod.billing_details.address.city || "City" }},
            {{ paymentMethod.billing_details.address.country || "Country" }}
            <br />
            {{ paymentMethod.billing_details.address.postal_code || 12345 }}
          </p>
          <primary-button (click)="removePaymentMethod($event, i)" class="mb-1">
            <p text class="text-sm">Remove Card</p>
          </primary-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex flex-col items-end">
  <div class="flex">
    <icon-lock class="w-4 h-4 mr-1 mb-1"></icon-lock>
    <div class="font-sans text-xs tracking-tight">
      Guaranteed <span class="font-bold">safe & secure</span> checkout
    </div>
  </div>
</div>
<div *ngIf="!disable.includes('input')" class="my-2" #cardElement></div>
<div class="flex flex-col items-end">
  <img class="w-48" src="/assets/image24.png" alt="" />
</div>

<primary-button
  *ngIf="!disable.includes('add')"
  (click)="addPaymentMethod()"
  class="mb-12 text-gray-700"
>
  <icon-plus icon class="h-4 w-4 mr-1 my-4"></icon-plus>
  <p text>Add payment method</p>
</primary-button>
