import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-attachment",
  templateUrl: "./attachment.component.html",
  styleUrls: ["./attachment.component.scss"],
})
export class AttachmentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
