import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-arrow-right",
  templateUrl: "./arrow-right.component.html",
  styleUrls: ["./arrow-right.component.scss"],
})
export class ArrowRightComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
