import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AppErrorStateMatcher } from "../../../utils/mat-matchers/app-errorstate.matcher";

@Component({
  selector: "app-textarea",
  templateUrl: "./textarea.component.html",
  styleUrls: ["./textarea.component.scss"],
})
export class TextareaComponent implements AfterViewInit {
  @Input() id: string;
  @Input() label: string;
  @Input() hint: string;
  @Input() placeholder: string = "";
  @Input() type = "text";
  @Input() required = false;
  @Input() onFocus: () => void = () => {};
  @Input() set value(val: string) {
    this._value = val;
    if (this.isValidInputValue(this._value)) {
      this.textareaFormControl.setValue(this._value);
    }
  }
  @Input() set defaultValue(value: string | null) {
    if (this._defaultValue === null && this.isValidInputValue(value)) {
      this._defaultValue = JSON.parse(JSON.stringify(value));
    }
  }
  get defaultValue() {
    return this._defaultValue;
  }
  @Output() valueChange = new EventEmitter<string>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() hasDiverged = new EventEmitter<boolean>();

  textareaFormControl: FormControl;
  matcher = new AppErrorStateMatcher();
  focus = false;
  _value: string;
  _defaultValue: string | null = null;
  _hasDiverged: boolean = false;

  constructor(private ref: ChangeDetectorRef) {
    this.textareaFormControl = new FormControl("", []);
    this.selectValidations();
  }

  ngOnInit() {
    this.textareaFormControl.valueChanges.subscribe((val) => {
      this.valid.emit(this.textareaFormControl.valid);
      this.valueChange.emit(val);
      this._hasDiverged = val !== this._defaultValue;
      this.hasDiverged.emit(this._hasDiverged);
    });
  }

  ngAfterViewInit() {}

  private isValidInputValue(inputValue) {
    return (
      inputValue !== null &&
      inputValue !== undefined &&
      typeof inputValue === "string" &&
      inputValue.toString().length >= 0
    );
  }

  public reset() {
    this.textareaFormControl.reset(this._defaultValue);
  }

  public clearInput() {
    this.textareaFormControl.reset();
  }

  selectValidations() {
    const validations = [];
    if (this.required) {
      validations.push(Validators.required);
    }

    this.textareaFormControl.setValidators(validations);
    this.textareaFormControl.updateValueAndValidity();
  }

  handleFocus() {
    this.focus = true;
    this.onFocus();
  }

  handleBlur() {
    this.focus = false;
  }
}
