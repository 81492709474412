<svg [ngClass]="{ 'animate-spin': animate }" viewBox="0 0 12 12">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.00005 12.0001C9.31378 12.0001 12.0001 9.31378 12.0001 6.00005C12.0001 2.68631 9.31378 0 6.00005 0C2.68631 0 0 2.68631 0 6.00005C0 9.31378 2.68631 12.0001 6.00005 12.0001ZM5.98535 10C8.19449 10 9.98535 8.20914 9.98535 6C9.98535 3.79086 8.19449 2 5.98535 2C3.77621 2 1.98535 3.79086 1.98535 6C1.98535 8.20914 3.77621 10 5.98535 10Z"
    fill="#C4C4C4"
  />
  <path
    fill-rule="evenodd"
    d="M12 6C12 9.31396 9.31348 12 6 12V10C8.20215 9.99219 9.98535 8.2041 9.98535 6H12Z"
    fill="#8B8B8B"
  />
</svg>
