import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, PLATFORM_ID } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { environment } from "@environments/environment";
import { filter, map } from "rxjs/operators";

declare const gtag: Function;

interface PageMetaData {
  title: string;
  description: string;
  keywords: string;
  imagePath: string;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta
  ) {
    if (isPlatformBrowser(this.platformId)) {
      gtag("config", environment.googleTrackingId, {
        app_version: environment.version,
      });
    }

    if (!environment.production) {
      this.meta.addTag({
        name: "robots",
        content: "noindex",
      });
    } else {
      console.log = () => {};
    }
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data) {
              return child.snapshot.data;
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((routeData: any) => {
        if (routeData.meta) {
          this.setMetaData(routeData.meta);
        }
      });
  }

  setMetaData(data: PageMetaData) {
    let { title, description, imagePath, keywords } = data;

    if (!environment.production) {
      this.meta.addTag({
        name: "robots",
        content: "noindex",
      });
    }

    if (title) {
      this.title.setTitle(title);
      this.meta.updateTag({
        property: "og:title",
        content: title,
      });
      this.meta.updateTag({
        property: "twitter:title",
        content: title,
      });
    }

    if (description) {
      this.meta.updateTag({
        name: "description",
        content: description,
      });
      this.meta.updateTag({
        property: "og:description",
        content: description,
      });
      this.meta.updateTag({
        property: "twitter:description",
        content: description,
      });
    }

    if (imagePath) {
      this.meta.updateTag({
        property: "og:image",
        content: environment.domain + imagePath,
      });
      this.meta.updateTag({
        property: "twitter:image",
        content: environment.domain + imagePath,
      });
      this.meta.updateTag({
        property: "twitter:card",
        content: "summary_large_image",
      });
    }

    this.meta.updateTag({
      property: "og:type",
      content: "website",
    });
    this.meta.updateTag({
      property: "og:url",
      content: environment.domain + this.router.url,
    });
    this.meta.updateTag({
      property: "twitter:url",
      content: environment.domain + this.router.url,
    });
  }
}
