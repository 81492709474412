import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-prohura",
  templateUrl: "./prohura.component.html",
  styleUrls: ["./prohura.component.scss"],
})
export class ProhuraComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
