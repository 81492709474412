import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-folder",
  templateUrl: "./folder.component.html",
  styleUrls: ["./folder.component.scss"],
})
export class FolderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
