<div class="w-full font-standard text-gray-800 dark:text-gray-200">
  <div class="mt-2 mb-4">
    <app-billing-plan></app-billing-plan>
  </div>
  <div class="mb-2">
    <app-payment-method
      disable="['methods', 'add']"
      [disableDarkMode]="true"
    ></app-payment-method>
  </div>

  <!-- <div class="my-16">
    <app-billing-information></app-billing-information>
  </div> -->
  <!-- <div class="my-16">
    <app-billing-history></app-billing-history>
  </div> -->
</div>
