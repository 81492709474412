<div class="flex flex-col text-left font-standard w-full">
  <h2 class="text-xl font-semibold">Pricing Calculator</h2>
  <p class="my-2 text-gray-600 text-sm">
    Calculate your monthly or yearly spend
  </p>

  <app-billing-length-toggle
    [length]="planDuration"
    (lengthChange)="handlePlanDurationChange($event)"
    class="my-4 mx-auto"
  ></app-billing-length-toggle>

  <div class="flex flex-col lg:flex-row">
    <div class="flex flex-col order-2 lg:order-1 w-full lg:w-1/2">
      <h4 class="mt-8 text-lg text-gray-800 font-medium">
        How big is your team?
      </h4>
      <h3 class="my-2 text-2xl font-semibold">{{ adminCount }}</h3>
      <input
        type="range"
        min="1"
        max="55"
        class="slider"
        [ngModel]="adminCount"
        (ngModelChange)="handleAdminCountChange($event)"
      />

      <h4 class="mt-8 text-lg text-gray-800 font-medium">
        How many clients do you work with?
      </h4>
      <h3 class="my-2 text-2xl font-semibold">
        {{ clientCount == 30 ? "30+" : clientCount }}
      </h3>
      <input
        type="range"
        min="1"
        max="30"
        class="slider"
        [(ngModel)]="clientCount"
      />

      <h4 class="mt-8 text-lg text-gray-800 font-medium">Do you want help?</h4>
      <input
        class="hidden"
        type="radio"
        name="help"
        id="yes"
        (click)="handlePlanHelpChange(true)"
      />
      <label for="yes" class="flex items-center"
        ><div
          class="h-5 w-5 my-4 mr-4 border-2 border-black rounded-full flex items-center justify-center cursor-pointer"
        >
          <div
            class="h-3 w-3 bg-black rounded-full"
            [ngClass]="{ hidden: !planHelp }"
          ></div>
        </div>
        Yes, I want monthly strategy calls</label
      >
      <input
        class="hidden"
        type="radio"
        name="help"
        id="no"
        (click)="handlePlanHelpChange(false)"
      />
      <label for="no" class="flex items-center">
        <div
          class="h-5 w-5 mr-4 border-2 border-black rounded-full flex items-center justify-center cursor-pointer"
        >
          <div
            class="h-3 w-3 bg-black rounded-full"
            [ngClass]="{ hidden: planHelp }"
          ></div>
        </div>
        No</label
      >
    </div>
    <div class="mx-auto flex flex-col order-1 lg:order-2">
      <h4 class="text-lg font-semibold font-exo2">{{ planName }}</h4>
      <span class="w-56 my-4 font-bold flex items-baseline">
        <h2 class="text-5xl">${{ planPrice }}</h2>
        <h4 class="text-2xl text-gray-700">/{{ planDuration }}</h4>
      </span>
      <color-button [routerLink]="['/signup']">
        <p text>Sign Up</p>
      </color-button>
    </div>
  </div>
</div>
