import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.scss"],
})
export class ImageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
