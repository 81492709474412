import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { StripeLookupKeys } from "src/entity_types";

export interface Plan {
  _id: number | string;
  cost: number;
  term: string;
  term_desc: string;
  users: string;
  meeting?: string;
  isFeatured: boolean;
  features: string[];
  name: string;
  lookup_key: StripeLookupKeys;
  price?: any;
  term_non_admin_cost: number;
  term_admin_cost: number;
}

@Injectable({
  providedIn: "root",
})
export class PlanService {
  free_features = [
    "Unlimited Clients",
    "Admin Tools",
    "Two-Click Status Updates",
    "Access to Step History",
    "OAuth with Google",
  ];
  pro_features = [...this.free_features];
  premium_features = [
    ...this.pro_features,
    "Monthly Strategy Meeting with Prohura",
  ];

  plans: Plan[] = [
    {
      _id: 0,
      name: "Prohura Free",
      cost: 0.0,
      term: "month",
      term_desc: "/m/admin",
      users: "1 admin user",
      isFeatured: false,
      features: this.free_features,
      lookup_key: StripeLookupKeys.FREE,
      term_non_admin_cost: 0,
      term_admin_cost: 7,
    },
    {
      _id: 0.5,
      name: "Prohura Free",
      cost: 0.0,
      term: "year",
      term_desc: "/y/admin",
      users: "1 admin user",
      isFeatured: false,
      features: this.free_features,
      lookup_key: StripeLookupKeys.FREE,
      term_non_admin_cost: 0,
      term_admin_cost: 7,
    },
    {
      _id: 1,
      name: "Prohura Pro",
      cost: 7.0,
      term: "month",
      term_desc: "/m/admin",
      users: "2+ admin users",
      isFeatured: false,
      features: this.pro_features,
      lookup_key: StripeLookupKeys.PRO_MONTH,
      term_non_admin_cost: 0,
      term_admin_cost: 7,
    },
    {
      _id: 1.5,
      name: "Prohura Pro",
      cost: 7.0 * 12,
      term: "year",
      term_desc: "/y/admin",
      users: "2+ admin users",
      isFeatured: false,
      features: this.pro_features,
      lookup_key: StripeLookupKeys.PRO_YEAR,
      term_non_admin_cost: 0,
      term_admin_cost: 7 * 12,
    },
    {
      _id: 2,
      name: "Prohura Premium",
      cost: 450.0,
      term: "month",
      term_desc: "/m + Admins",
      users: "2+ admin users",
      meeting: "Monthly Strategy Call",
      isFeatured: true,
      features: this.premium_features,
      lookup_key: StripeLookupKeys.PREMIUM_MONTH,
      term_non_admin_cost: 450,
      term_admin_cost: 7,
    },
    {
      _id: 2.5,
      name: "Prohura Premium",
      cost: 450.0 * 12,
      term: "year",
      term_desc: "/y + Admins",
      users: "2+ admin users",
      meeting: "Monthly Strategy Call",
      isFeatured: true,
      features: this.premium_features,
      lookup_key: StripeLookupKeys.PREMIUM_YEAR,
      term_non_admin_cost: 450 * 12,
      term_admin_cost: 7 * 12,
    },
  ];
  prices: [];

  constructor() {}

  getPlans(percentageOff: number = 0) {
    const plans = this.plans.map((plan) => {
      return {
        ...plan,
        cost: plan.cost - plan.cost * (percentageOff / 100),
        term_non_admin_cost:
          plan.term_non_admin_cost -
          plan.term_non_admin_cost * (percentageOff / 100),
        term_admin_cost:
          plan.term_admin_cost - plan.term_admin_cost * (percentageOff / 100),
      };
    });
    return of([...plans]);
  }
}
