<button
  [disabled]="disabled"
  [ngClass]="{
    'cursor-not-allowed': disabled,
    'cursor-pointer': !disabled
  }"
  style="background: linear-gradient(324.99deg, #9e61eb 21.27%, #004ea9 92.54%)"
  class="h-11 p-4 rounded flex items-center text-white text-sm justify-center font-standard font-medium w-full"
>
  <app-loading *ngIf="loading" class="w-5 h-5 mr-4"></app-loading>
  <ng-content *ngIf="loading" select="[loading-text]"></ng-content>

  <ng-content *ngIf="!loading" select="[text]"></ng-content>
</button>
