import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-arrow-right-circle",
  templateUrl: "./arrow-right-circle.component.html",
  styleUrls: ["./arrow-right-circle.component.scss"],
})
export class ArrowRightCircleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
