<svg
  class="w-6 h-3 flex items-center justify-center"
  height="100%"
  width="100%"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
>
  <rect width="3px" height="100%" />
  <rect width="100%" height="3px" y="calc(50% - 1px)" />
  <rect width="3px" height="100%" x="calc(100% - 3px)" />
</svg>
