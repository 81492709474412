import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-vertical-dots",
  templateUrl: "./vertical-dots.component.html",
  styleUrls: ["./vertical-dots.component.scss"],
})
export class VerticalDotsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
