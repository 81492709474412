import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-chevron-double-left",
  templateUrl: "./chevron-double-left.component.html",
  styleUrls: ["./chevron-double-left.component.scss"],
})
export class ChevronDoubleLeftComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
