<div
  class="relative h-10 w-80 my-2 border-2 rounded bg-gray-300 flex items-center justify-between overflow-hidden cursor-pointer"
>
  <p
    *ngFor="let option of options"
    class="z-20 w-1/2 h-full flex items-center justify-center text-center text-sm font-semibold"
    (click)="handleClick(option)"
  >
    {{ option }}
  </p>
  <span
    [@toggle]="selectedOption"
    class="absolute z-10 w-1/2 h-full rounded bg-white"
  ></span>
</div>
