<svg
  width="24"
  height="24"
  viewBox="0 0 96 96"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect y="72.6816" width="27.6867" height="17.3042" />
  <rect x="34.6133" y="27.6895" width="25.9563" height="62.2951" />
  <rect x="67.8145" width="27.3858" height="89.9818" />
</svg>
