export enum AccountRoles {
  ADMIN = "admin",
  ADMIN_OWNER = "admin_owner",
  CLIENT = "client",
}

export enum StripeLookupKeys {
  FREE = "free",
  PRO_MONTH = "pro-month",
  PRO_YEAR = "pro-year",
  PREMIUM_MONTH = "premium-month",
  PREMIUM_YEAR = "premium-year",
}

export enum AppAccessStatus {
  ALLOWED = "allowed",
  DENIED = "denied",
}
