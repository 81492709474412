import { Injectable } from "@angular/core";
import Amplify, { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { environment } from "@environments/environment";
import { Router } from "@angular/router";
import { Account, AccountRoles, AccountStatus } from "@shared/models";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: environment.cognitoUserPoolId,
    userPoolWebClientId: environment.cognitoUserPoolWebClientId,
    oauth: {
      domain: environment.cognitoDomain,
      scope: ["phone", "email", "profile", "openid"],
      redirectSignIn: `${environment.domain}/app`,
      redirectSignOut: `${environment.domain}/`,
      responseType: "code",
    },
  },
});

type user = { accounts; email: string; shared?: boolean };
@Injectable({
  providedIn: "root",
})
export class AuthService {
  token: string;
  user: user;
  currentAccount: Partial<Account>;
  private role: AccountRoles;
  account_status: AccountStatus;
  shared: boolean;
  customState: any = {
    url: null,
  };
  Auth = Auth;

  constructor(private router: Router) {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log({ event, data });
      switch (event) {
        case "signIn":
          break;
        case "signOut":
          this.router.navigate(["/"]);
          // console.log(event, data);
          break;
        case "customOAuthState":
          data = data && JSON.parse(data);
          if (data.url) {
            this.customState.url = data.url;
          }
      }
    });
  }

  setToken(token: string) {
    // console.log({ token });
    const parsedToken = this.parseToken(token);
    // console.log({ parsedToken });

    this.shared = parsedToken.shared;
    const { accounts } = parsedToken;
    if (!accounts) throw new Error("Invalid Token");

    this.token = token;
    localStorage.setItem("RP_TK", token);
    this.user = parsedToken;
    if (this.user.accounts && this.user.accounts.length === 1) {
      this.setCurrentAccount(this.user.accounts[0]);
    }

    const account = this.getCurrentAccount();
    if (account) {
      this.role = account.role;
      this.account_status = account.account_status;
    }
  }

  getLocalToken() {
    const token = localStorage.getItem("RP_TK");
    // console.log({ token });
    this.setToken(token); //set auth service properties
    return token;
  }

  async refreshSession() {
    const session = await Auth.currentSession();
    const refresh_token = await session.getRefreshToken();
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const refreshed = await new Promise(function (resolve, reject) {
      cognitoUser.refreshSession(refresh_token, resolve);
    });
    await this.getAmplifyIdToken();
    return refreshed;
  }

  async getAmplifyIdToken() {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    // console.log({ token });
    if (token) {
      this.setToken(token);
    }
    return token;
  }

  async getUserAndCurrentAccount() {
    const user = await this.getUserInfo();
    if (user) {
      this.user = user;
      const account = this.getCurrentAccount();
      return { user, account };
    } else {
      return null;
    }
  }

  getCurrentAccount(): Partial<Account> {
    if (this.user && this.user.accounts && this.user.accounts.length === 1) {
      this.currentAccount = { ...this.user.accounts[0] };
      return { ...this.currentAccount };
    } else {
      const storedCurrentAccountId = localStorage.getItem(
        "RP_CURRENT_ACCOUNT_ID"
      );
      // console.log({ storedCurrentAccountId });
      if (storedCurrentAccountId && this.user && this.user.accounts) {
        const account = this.user.accounts.find((account) => {
          // console.log(account._id, storedCurrentAccountId);
          return account._id === storedCurrentAccountId;
        });
        if (account) {
          this.currentAccount = account;
          return { ...this.currentAccount };
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }

  setCurrentAccount(account: Partial<Account>) {
    this.currentAccount = { ...account };
    localStorage.setItem("RP_CURRENT_ACCOUNT_ID", this.currentAccount._id);
    const _acc = localStorage.getItem("RP_CURRENT_ACCOUNT_ID");
    // console.log("set to", _acc);
  }

  async getUserInfo() {
    let token;
    try {
      token = await this.getAmplifyIdToken();
    } catch (e) {
      const localToken = this.token || this.getLocalToken();
      if (localToken) {
        //token from link params
        return this.parseToken(localToken);
      }
    }
    return this.parseToken(token);
  }

  async continueWithGoogle(state?: object) {
    const user = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: JSON.stringify(state || {}),
    });
    // console.log(user);
  }

  async signOut() {
    localStorage.removeItem("RP_CURRENT_ACCOUNT_ID");
    localStorage.removeItem("RP_TK");
    await Auth.signOut();
  }

  getRole() {
    return this.currentAccount?.role;
  }

  private parseToken(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    // console.log({ jsonPayload });
    const user: user = JSON.parse(jsonPayload);
    user.accounts = JSON.parse(user.accounts);
    return user;
  }
}
