import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-presentation-line",
  templateUrl: "./presentation-line.component.html",
  styleUrls: ["./presentation-line.component.scss"],
})
export class PresentationLineComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
