import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";

@Component({
  selector: "app-billing-length-toggle",
  templateUrl: "./billing-length-toggle.component.html",
  styleUrls: ["./billing-length-toggle.component.scss"],
  animations: [
    trigger("toggle", [
      state(
        "month",
        style({
          transform: "translateX(0%)",
        })
      ),
      state(
        "year",
        style({
          transform: "translateX(100%)",
        })
      ),
      transition("month => year", [animate("0.25s ease-in-out")]),
      transition("year => month", [animate("0.25s ease-in-out")]),
    ]),
  ],
})
export class BillingLengthToggleComponent implements OnInit {
  @Input("length") length: "month" | "year" = "month";

  @Output("lengthChange") lengthChange: EventEmitter<"month" | "year"> =
    new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleClick(length: "month" | "year") {
    this.length = length;
    this.lengthChange.emit(length);
  }
}
