import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
