import Package from "../../package.json";

export const environment = {
  version: Package.version + "-test",
  production: false,
  baseUrl: "https://api.test.prohura.com",
  domain: "https://test.prohura.com",
  cognitoUserPoolId: "us-east-1_nKcUZME6G",
  cognitoUserPoolWebClientId: "5468jakp0aknt9apco8qhn8jrj",
  cognitoDomain: "auth.test.prohura.com",
  stripePk:
    "pk_test_51I84K4DSFiO79ZcXKqHpLnFpwcrhoepknVaKG6c8pjbkd177JSPQgROXNEppc5yvKe0bZYsWTD1jIJH6w6TCC08z00fV7QTssf",
  googleTrackingId: "",
};
