import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-americanexpress",
  templateUrl: "./americanexpress.component.html",
  styleUrls: ["./americanexpress.component.scss"],
})
export class AmericanexpressComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
