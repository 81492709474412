import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "color-button",
  templateUrl: "./color-button.component.html",
  styleUrls: ["./color-button.component.scss"],
})
export class ColorButtonComponent implements OnInit {
  @Input() loading = false;
  @Input() disabled = false;

  constructor() {}

  ngOnInit(): void {}
}
