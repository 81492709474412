<div class="flex flex-col w-full" style="min-width: 192px">
  <div
    class="relative h-14 w-full border-2 rounded flex items-center font-standard dark:bg-black"
    [ngClass]="{
      'border-gray-900 dark:border-gray-200': focus,
      'border-gray-500 hover:border-gray-700 dark:hover:border-gray-300': !focus
    }"
  >
    <label
      *ngIf="label"
      class="absolute left-2 pl-1 bg-white dark:bg-black cursor-text transition-all w-auto h-0.5 -top-0.5 text-transparent z-10 pointer-events-none"
      [ngClass]="{
        'bg-white dark:bg-black': focus || inputFormControl.value?.length,
        'bg-transparent': !focus && !inputFormControl.value?.length
      }"
    >
      {{ formattedLabel }}
    </label>
    <label
      *ngIf="label"
      [for]="label"
      [ngClass]="{
        '-top-3 text-sm text-gray-900 dark:text-gray-200':
          focus || inputFormControl.value?.length,
        'top-3.5 text-base text-gray-500':
          !focus && !inputFormControl.value?.length
      }"
      class="absolute left-2 px-1 bg-transparent cursor-text transition-all w-auto z-10 pointer-events-none"
      [ngStyle]="
        !focus &&
        !inputFormControl.value?.length && { width: 'calc(100% - 0.5rem)' }
      "
      >{{ formattedLabel }}</label
    >
    <input
      [id]="id"
      autocomplete="off"
      [formControl]="inputFormControl"
      class="h-full w-full p-4 rounded absolute z-0 text-base outline-none dark:text-white/[.87] dark:bg-black"
      [ngClass]="{
        'pr-10': hasIcon,
        'placeholder-gray-400 dark:placeholder-gray-600':
          focus && inputFormControl.value?.length,
        'placeholder-transparent':
          !focus && !inputFormControl.value?.length && label
      }"
      [type]="type"
      [maxlength]="maxlength"
      [name]="label"
      [placeholder]="placeholder"
      [required]="required"
      (focus)="handleFocus()"
      (blur)="handleBlur()"
    />

    <div
      #iconSlot
      class="h-5 w-5 right-4 text-gray-500 z-10 absolute"
      [ngClass]="{ ' hidden': !hasIcon }"
    >
      <ng-content select="[icon]"></ng-content>
    </div>
  </div>
  <div class="h-4 mt-px">
    <ng-container *ngIf="inputFormControl.touched">
      <div
        *ngIf="inputFormControl.hasError('required')"
        class="text-xs text-red-500 pl-2 font-standard"
      >
        {{ label || placeholder }} is required.
      </div>
      <div
        *ngIf="
          type == 'email' &&
          inputFormControl.hasError('pattern') &&
          !inputFormControl.hasError('required')
        "
        class="text-xs text-red-500 pl-2 font-standard"
      >
        Please enter a valid email address.
      </div>
      <div
        *ngIf="
          !inputFormControl.hasError('minlength') &&
          inputFormControl.hasError('strong') &&
          !inputFormControl.hasError('required')
        "
        class="text-xs text-red-500 pl-2 font-standard"
      >
        {{ label || placeholder }} must have at least one number, one lowercase
        and one uppercase letter.
      </div>
      <div
        *ngIf="
          inputFormControl.hasError('minlength') &&
          !inputFormControl.hasError('required')
        "
        class="text-xs text-red-500 pl-2 font-standard"
      >
        {{ label || placeholder }} must be at least {{ minlength }} characters
        long.
      </div>
      <div
        *ngIf="
          inputFormControl.hasError('maxlength') &&
          !inputFormControl.hasError('required')
        "
        class="text-xs text-red-500 pl-2 font-standard"
      >
        {{ label || placeholder }} must be at most {{ maxlength }} characters
        long.
      </div>
      <div *ngIf="_error" class="text-xs text-red-500 pl-2 font-standard">
        {{ _error }}
      </div>
    </ng-container>
  </div>
</div>
