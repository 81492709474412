import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-organization",
  templateUrl: "./organization.component.html",
  styleUrls: ["./organization.component.scss"],
})
export class OrganizationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
