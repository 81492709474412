import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-document-text",
  templateUrl: "./document-text.component.html",
  styleUrls: ["./document-text.component.scss"],
})
export class DocumentTextComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
