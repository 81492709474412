import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-link",
  templateUrl: "./link.component.html",
  styleUrls: ["./link.component.scss"],
})
export class LinkComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
