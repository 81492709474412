import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-pricing-calculator",
  templateUrl: "./pricing-calculator.component.html",
  styleUrls: ["./pricing-calculator.component.scss"],
})
export class PricingCalculatorComponent implements OnInit {
  adminCount: number = 1;
  clientCount: number = 1;

  planName: string = "Prohura Free";
  planPrice: number = 0;
  @Input() planDuration: "month" | "year" = "month";
  @Output() planDurationChange: EventEmitter<string> = new EventEmitter();
  planHelp: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  handlePlanDurationChange(event: "month" | "year") {
    this.planDuration = event;
    this.planDurationChange.emit(this.planDuration);
    this.calculatePlan();
  }

  handleAdminCountChange(event: number) {
    this.adminCount = event;
    this.calculatePlan();
  }

  handlePlanHelpChange(event: boolean) {
    this.planHelp = event;
    this.calculatePlan();
  }

  private calculatePlan() {
    if (this.planHelp) {
      this.planName = "Prohura Premium";
      if (this.planDuration === "month") {
        this.planPrice = 450 + (this.adminCount - 1) * 7;
      } else {
        this.planPrice = (450 + (this.adminCount - 1) * 7) * 12;
      }
    } else {
      if (this.planDuration === "month") {
        if (this.adminCount < 2) {
          this.planName = "Prohura Free";
          this.planPrice = 0;
        } else if (this.adminCount >= 2) {
          this.planName = "Prohura Pro";
          this.planPrice = (this.adminCount - 1) * 7;
        }
      } else {
        if (this.adminCount < 2) {
          this.planName = "Prohura Free";
          this.planPrice = 0;
        } else if (this.adminCount >= 2) {
          this.planName = "Prohura Pro";
          this.planPrice = (this.adminCount - 1) * 7 * 12;
        }
      }
    }
  }
}
