import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  private darkMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private systemPreferencePoll: NodeJS.Timeout;

  constructor() {
    const theme = localStorage.getItem("pr-theme");

    switch (theme) {
      case "light":
        this.darkMode.next(false);
        break;
      case "dark":
        this.darkMode.next(true);
        break;
      default:
        let system = window.matchMedia("(prefers-color-scheme: dark)").matches;
        this.darkMode.next(system);
        this.systemPreferencePoll = setInterval(() => {
          system = window.matchMedia("(prefers-color-scheme: dark)").matches;
          if (system !== this.darkMode.getValue()) {
            this.darkMode.next(system);
          }
        }, 2 * 1000);
        break;
    }
  }

  getDarkMode(): Observable<boolean> {
    return this.darkMode.asObservable();
  }

  toggleDarkMode() {
    if (this.systemPreferencePoll) {
      clearInterval(this.systemPreferencePoll);
    }
    const newValue = !this.darkMode.getValue();
    this.darkMode.next(newValue);
    localStorage.setItem("pr-theme", newValue ? "dark" : "light");
  }
}
