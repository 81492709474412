<div
  #statusRef
  class="relative flex items-center justify-center"
  [ngClass]="{ 'cursor-default': !admin, 'cursor-pointer': admin }"
  (click)="handleOpen($event)"
>
  <svg class="h-8 w-8 rounded-full" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="50%"
      cy="50%"
      r="16"
      fill="none"
      stroke="currentColor"
      stroke-width="10"
    />
  </svg>
  <icon-check
    *ngIf="status === 'Completed'"
    class="absolute h-5 w-5"
  ></icon-check>
  <svg *ngIf="status === 'In Progress'" class="absolute" viewBox="0 0 12 12">
    <path
      fill-rule="evenodd"
      d="M12 6C12 9.31396 9.31348 12 6 12V10C8.20215 9.99219 9.98535 8.2041 9.98535 6H12Z"
      fill="#4d4d4d"
    />
  </svg>
  <div
    *ngIf="status === 'Not Started'"
    class="absolute h-2.5 w-2.5 border-2 border-gray-500 rounded-sm"
  ></div>
  <icon-archive
    *ngIf="status === 'Archived'"
    class="absolute h-3 w-3 text-gray-500"
  ></icon-archive>

  <form
    [formGroup]="form"
    *ngIf="open"
    class="absolute z-10 bg-gray-50 dark:bg-darkgray-800 text-gray-600 dark:text-gray-400 shadow-sm rounded border-2 border-gray-300 dark:border-gray-700 text-sm whitespace-nowrap overflow-hidden"
    [ngStyle]="{ bottom: bottom }"
  >
    <label
      class="px-3 py-1.5 flex items-center hover:bg-gray-700 hover:text-white cursor-pointer"
      for="Not Started"
    >
      <icon-stop class="h-4 w-4 mr-2"></icon-stop>
      Not Started
    </label>
    <input
      formControlName="status"
      name="status"
      type="radio"
      id="Not Started"
      value="Not Started"
      class="hidden"
    />
    <label
      class="px-3 py-1.5 flex items-center hover:bg-gray-700 hover:text-white cursor-pointer"
      for="In Progress"
    >
      <app-loading class="h-4 w-4 mr-2" [animate]="false"></app-loading>
      In Progress
    </label>
    <input
      formControlName="status"
      name="status"
      type="radio"
      id="In Progress"
      value="In Progress"
      class="hidden"
    />
    <label
      class="px-3 py-1.5 flex items-center hover:bg-gray-700 hover:text-white cursor-pointer"
      for="Completed"
    >
      <icon-check class="h-4 w-4 mr-2"></icon-check>
      Completed
    </label>
    <input
      formControlName="status"
      name="status"
      type="radio"
      id="Completed"
      value="Completed"
      class="hidden"
    />
    <label
      class="px-3 py-1.5 flex items-center hover:bg-gray-700 hover:text-white cursor-pointer"
      for="Archived"
    >
      <icon-archive class="h-4 w-4 mr-2"></icon-archive>
      Archived
    </label>
    <input
      formControlName="status"
      name="status"
      type="radio"
      id="Archived"
      value="Archived"
      class="hidden"
    />
  </form>
</div>
