<button
  [id]="id"
  [disabled]="disabled"
  [ngClass]="{
    'cursor-not-allowed': disabled,
    'cursor-pointer': !disabled,
    'bg-white dark:bg-black': !loading,
    'bg-gray-300 dark:bg-gray-800': loading
  }"
  class="h-11 p-4 border border-black dark:border-white/[.87] rounded flex items-center hover:bg-gray-300 dark:hover:bg-gray-800 text-black dark:text-white/[.87] text-sm justify-center font-standard font-medium"
>
  <app-loading *ngIf="loading" class="w-5 h-5 mr-4"></app-loading>
  <ng-content *ngIf="loading" select="[loading-text]"></ng-content>

  <div
    class="h-5 w-5 mr-4"
    [ngClass]="{ ' hidden': !hasIcon || loading }"
    #iconSlot
  >
    <ng-content select="[icon]"></ng-content>
  </div>
  <ng-content *ngIf="!loading" select="[text]"></ng-content>
</button>
