import { Component, OnInit } from "@angular/core";

@Component({
  selector: "billing-plan-loading",
  templateUrl: "./billing-plan-loading.component.html",
  styleUrls: ["./billing-plan-loading.component.scss"],
})
export class BillingPlanLoadingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
