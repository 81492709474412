<div class="flex items-center">
  <svg
    style="height: 3.5px"
    class="w-4 -mr-0.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="100%" height="100%" fill="currentColor" />
  </svg>
  <svg
    style="box-shadow: inset 8px 0 3px 0 rgba(0, 0, 0, 0.1)"
    class="h-4 w-4 rounded-full"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="50%"
      cy="50%"
      r="8"
      fill="none"
      stroke="currentColor"
      stroke-width="5"
    />
    <circle
      cx="50%"
      cy="50%"
      r="4.5"
      fill="none"
      stroke="white"
      stroke-width="1"
    />
  </svg>
</div>
