import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-ordered-list",
  templateUrl: "./ordered-list.component.html",
  styleUrls: ["./ordered-list.component.scss"],
})
export class OrderedListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
