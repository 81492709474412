import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-unordered-list",
  templateUrl: "./unordered-list.component.html",
  styleUrls: ["./unordered-list.component.scss"],
})
export class UnorderedListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
