import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-office-building",
  templateUrl: "./office-building.component.html",
  styleUrls: ["./office-building.component.scss"],
})
export class OfficeBuildingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
