import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@services/auth.service";
import { Phase, Project, Service } from "@shared/models";
import { AccountRoles } from "src/entity_types";

@Component({
  selector: "process-preview",
  templateUrl: "./process-preview.component.html",
  styleUrls: ["./process-preview.component.scss"],
})
export class ProcessPreviewComponent implements OnInit {
  @Input("service") set service(service: Service) {
    this._service = service;
    if (service.process_template) {
      this.phases = JSON.parse(service.process_template as string).phases;
    }
  }
  @Input() set process_template(process_template: string) {
    if (process_template) {
      this.sortPhases(JSON.parse(process_template).phases);
    }
  }

  @Input() set project(project: Partial<Project>) {
    if (project) {
      this._project = project;
    }
  }
  @Input() mocked = false;

  phases: Phase[] = [];
  _service: Service;
  _project: Partial<Project>;
  isOwner: boolean;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    const role = this.authService.getRole();
    this.isOwner = AccountRoles.ADMIN_OWNER === role;
  }

  editServiceClick() {
    if (!this._service) return;

    this.router.navigateByUrl(`app/admin/services/${this._service._id}/edit`, {
      state: {
        service: this._service,
        returnUrl: this.router.url,
        project: this._project,
      },
    });
  }

  copyServiceClick() {
    if (!this._service) return;

    this.router.navigateByUrl(`app/admin/services/${this._service._id}/copy`, {
      state: {
        service: this._service,
        returnUrl: this.router.url,
        project: this._project,
      },
    });
  }

  private sortPhases(phases: Phase[]) {
    phases = phases.sort((a, b) => a.index - b.index);
  }
}
