import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-check",
  templateUrl: "./check.component.html",
  styleUrls: ["./check.component.scss"],
})
export class CheckComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
