import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-dollar",
  templateUrl: "./dollar.component.html",
  styleUrls: ["./dollar.component.scss"],
})
export class DollarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
