<div
  class="relative w-full border-2 rounded flex items-center font-standard dark:bg-black"
  [ngClass]="{
    'border-gray-900 dark:border-gray-100': focus,
    ' border-gray-500 hover:border-gray-700 dark:hover:border-gray-300': !focus
  }"
>
  <label
    *ngIf="label"
    class="absolute left-2 pl-1 bg-white dark:bg-black cursor-text transition-all w-auto h-0.5 -top-0.5 text-transparent z-10 pointer-events-none"
    [ngClass]="{
      'bg-white dark:bg-black': focus || textareaFormControl.value?.length,
      'bg-transparent': !focus && !textareaFormControl.value?.length
    }"
  >
    {{ label }}
  </label>
  <label
    *ngIf="label"
    [for]="label"
    [ngClass]="{
      '-top-3 text-sm text-gray-900 dark:text-gray-200':
        focus || textareaFormControl.value?.length,
      'top-3.5 text-base text-gray-500':
        !focus && !textareaFormControl.value?.length
    }"
    class="absolute left-2 px-1 bg-transparent cursor-text transition-all w-auto z-10 pointer-events-none"
    [ngStyle]="
      !focus &&
      !textareaFormControl.value?.length && { width: 'calc(100% - 0.5rem)' }
    "
    >{{ label }}</label
  >
  <textarea
    [id]="id"
    [formControl]="textareaFormControl"
    [ngClass]="{
      'placeholder-gray-400': focus && textareaFormControl.value?.length,
      'placeholder-transparent':
        !focus && !textareaFormControl.value?.length && label
    }"
    class="w-auto p-4 rounded text-base resize-none outline-none dark:text-white/[.87] dark:bg-black"
    [name]="label"
    [placeholder]="placeholder"
    [required]="required"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
    cols="50"
    rows="2"
  ></textarea>
  <p *ngIf="hint">{{ hint }}</p>
</div>
