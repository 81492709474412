import { Component, Input, OnInit } from "@angular/core";
import { StripeService } from "@services/stripe.service";

@Component({
  selector: "app-billing",
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.scss"],
})
export class BillingComponent implements OnInit {
  @Input() customerId: string;

  constructor() {}

  ngOnInit(): void {
    // console.log(this.customerId);
    // this.stripeService.createSetupIntent();
  }
}
