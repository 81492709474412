import { Component, OnInit } from "@angular/core";

@Component({
  selector: "icon-paper-airplane",
  templateUrl: "./paper-airplane.component.html",
  styleUrls: ["./paper-airplane.component.scss"],
})
export class PaperAirplaneComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
