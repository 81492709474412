<svg
  viewBox="0 0 24 24"
  stroke="currentColor"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6 18L18 6M6 6L18 18"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
