import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";

@Component({
  selector: "app-toggle-slider",
  templateUrl: "./toggle-slider.component.html",
  styleUrls: ["./toggle-slider.component.scss"],
  animations: [
    trigger("toggle", [
      state(
        "On",
        style({
          transform: "translateX(0%)",
        })
      ),
      state(
        "Off",
        style({
          transform: "translateX(100%)",
        })
      ),
      transition("On => Off", [animate("0.25s ease-in-out")]),
      transition("Off => On", [animate("0.25s ease-in-out")]),
    ]),
  ],
})
export class ToggleSliderComponent implements OnInit {
  @Input("options") options: string[] = ["On", "Off"];

  @Output("selectedOptionChange") selectedOptionChange: EventEmitter<string> =
    new EventEmitter();

  selectedOption: string = this.options[0];

  constructor() {}

  ngOnInit(): void {}

  handleClick(option: string) {
    this.selectedOption = option;
    this.selectedOptionChange.emit(option);
  }
}
